import React from "react";

import { useState } from "react";
const coldWeatherToDosOutdoors = [
  "Bundle up and build a snowman",
  "Bundle up and go for a short walk",
  "Bundle up and gather snow to make snow ice cream",
  "Bundle up and deliver some painted rocks to your friends",
  "Please bundle up if you take your little ones outdoors in the cold!",
  "Go for a family drive",
  "Collect pine cones",
  "Take pictures of things in nature",
  "Find some sticks and make a fence for toy animals",
  "Make nature confetti by hole punching leaves",
  "Collect flowers, berries and leaves for a nature collage",
  "Write kind words in chalk on your sidewalk",
  "Learn about what types of evergreens are around you",
  "Make a snowman",
  "Plant some onions",
  "Spot animal tracks on a walk",
  "Collect dried grass and make a nest",
  "Make maple syrup snow candy",
  "Study snowflakes that fall onto construction paper",
  "Check out the animals at your local zoo",
  "Make a percussion area with buckets, chimes, and cans-then dance and sing outside",
  "Go see how they tap trees for sap",
  "Make snow castles with your summer toys",
  "Go sledding",
  "Winter scavenger hunt (pincones, acorns, animal tracks, frozen puddles, icicles, feathers, lots of stuff to find!)",
  "Build a stick fort for your doll (don't forget to bring the doll back in!)",
  "Flashlight tag since the sun sets early",
  "Write letters or numbers with a stick in the snow",
  "Visit a botanical garden",
  "Winter photograph scavenger hunt",
  "Make stick wind chimes to give your family and friends",
  "Tic Tac Toe in the snow",
  "Dissect a pinecone",
  "Visit a park",
  "Take binoculars on a nature walk",
  "Find winter constellations",
  "Make bark rubbings with crayons",
  "Please bundle up if you take your little ones outdoors in the cold!",
  "Go sledding",
  "Go ice skating", 
  "Make snow angels",
  "Cold weather nature scavenger hunt",
  "Snow painting",
  "Outdoor obstacle course",
  "Go on a wintery hike",
  "Set up a bird feeder outside the window",
  "Take winter photos",
  "Build a mini snow hut",
  "Make frozen bubbles",
  "Tap a tree for syrup",
  "Go snowshoeing",
  "Make a winter bonfire",
  "Wintry nature collage",
  "Frosty leaf prints",
  "Go on a rock hunt outside",
  "Create a things you found in nature collage",
  "Play a find the creature game with binoculars",
  "Collect twigs and wrap with colorful yarn for friends",
  "Make wind spinners with beads to hang in your trees",
  "Winter photo scavenger hunt",
  "Make a snow maze for toy cars",
  "Winter sky stargazing",
  "Geocaching",
  "Make a winter carnival game",
  "Cook on a campfire",
  "Make footprints in the snow",
  "Snow Tic Tac Toe",
  "Sled relay race with your friends", 
  "Visit a farm",
  "Go to the zoo", 
  "Go to the library", 
  "Make shadow art", 
  "Outdoor cold weather kitchen",
  "Make a leaf necklace",
  "Build a bug house with sticks, mud and leaves",
  "Spell your name with birdseed",
  "Go on a bird call scavenger hunt",
  "Go puddle jumping in your boots",
  "Paint the mud with a stick",
  "Set up a glow stick hunt after dark",
  "Shovel a snow maze",
  "Try to learn a bird call",


];
export default function OutdoorsCold() {
  const [list, setList] = useState(coldWeatherToDosOutdoors);
  const shuffleActivityList = () => {
    for (let i = list.length; --i; ) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    setList([...list]);
  };
  return (
    <>
      <li>{list[0]}</li>
      <li>{list[1]}</li>
      <li>{list[2]}</li>
      <li>{list[3]}</li>
      <li>{list[4]}</li>
      <button id="custom-suggestion-button" onClick={shuffleActivityList}>
        Shuffle activities
      </button>
    </>
  );
}
