import React from "react";
import HotWeatherToDosIndoors from "./HotWeatherIndoors";
import HotWeatherToDosOutdoors from "./HotWeatherOutdoors";
import IndoorsWarm from "./IndoorsWarm";
import OutdoorsWarm from "./OutdoorsWarm";
import IndoorsChilly from "./IndoorsChilly";
import IndoorsCold from "./IndoorsCold";
import OutdoorsCold from "./OutdoorsCold";
import "../styles/styles.scss";
import OutdoorsChilly from "./OutdoorsChilly";

import Accordion from "react-bootstrap/Accordion";

function Suggestions(props) {
  return (
    <>
      <div>
        {props.data.tempforsuggestions >= 80 ? (
          <h3>Today will be one hot day! What can we do?</h3>
        ) : props.data.tempforsuggestions >= 60 &&
          props.data.tempforsuggestions <= 79 ? (
          <h3>Today is going to be a warm day! What can we do?</h3>
        ) : props.data.tempforsuggestions <= 59 &&
          props.data.tempforsuggestions >= 40 ? (
          <h3>Today is going to be chilly! What can we do?</h3>
        ) : (
          <h3>Today will be cold! What can we do?</h3>
        )}
      </div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span id="accordion-text">Indoor Activities</span>
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              {" "}
              {props.data.tempforsuggestions >= 80 ? (
                <div>{<HotWeatherToDosIndoors />}</div>
              ) : props.data.tempforsuggestions <= 79 &&
                props.data.tempforsuggestions >= 60 ? (
                <div>{<IndoorsWarm />}</div>
              ) : props.data.tempforsuggestions <= 59 &&
                props.data.tempforsuggestions >= 40 ? (
                <div>{<IndoorsChilly />}</div>
              ) : (
                <div>{<IndoorsCold />}</div>
              )}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span id="accordion-text">Outdoor Activities</span>
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              {props.data.tempforsuggestions >= 80 ? (
                <div>{<HotWeatherToDosOutdoors />}</div>
              ) : props.data.tempforsuggestions >= 60 &&
                props.data.tempforsuggestions <= 79 ? (
                <div>{<OutdoorsWarm />}</div>
              ) : props.data.tempforsuggestions >= 40 &&
                props.data.tempforsuggestions <= 59 ? (
                <div>{<OutdoorsChilly />} </div>
              ) : (
                <div>{<OutdoorsCold />}</div>
              )}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default Suggestions;
