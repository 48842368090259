import { useState } from "react";
import React from "react";
const warmWeatherToDosOutdoors = [
  "Go for a hike, don't forget snacks and drinks!",
  "Go to the park",
  "Head to the zoo",
  "Make a stick fort",
  "Make a sidewalk chalk race track for cars!",
  "Make an outdoor kitchen",
  "Build a pretend campsite",
  "Play Legos on the picnic table",
  "Color and draw outside",
  "Make a picnic",
  "Watercolor paint what you see in nature",
  "Play what's in the box",
  "Make bubble snakes with water bottles",
  "Make your own bubble wands with pipe cleaners and beads",
  "Set up a car wash for bikes",
  "Water balloon target toss",
  "Paint on ice cubes",
  "Water spray art",
  "Paint rocks for friends and family",
  "Dip a Koosh ball in paint and use it as a stamper",
  "Paint golf balls to give as presents",
  "Use grass as a paint brush to make texture art",
  "Make a bean bag toss game with old socks and beans",
  "Trace each other's shadow with sidewalk chalk",
  "Trace your toys with sidewalk chalk",
  "Make colored sand art in a bottle",
  "Collect shells",
  "Pick flowers",
  "Make pictures with glued on cotton ball clouds",
  "Collect items for a collage",
  "Make sunprint pictures of toys or things you find",
  "Make a lei with flowers, pastas, beads or other items",
  "Paint your own stepping stones for a pathway",
  "Use puffy paint to make a shirt",
  "Make an ocean in a bottle-seal it well",
  "Freeze little prizes into ice cubes for excavation",
  "Make a tissue paper parachute for Lego figures and watch the wind catch them",
  "Go for a hike, don't forget snacks and drinks!",
  "Go to the park",
  "Head to the zoo",
  "Make a stick fort",
  "Make a sidewalk chalk race track for cars!",
  "Make an outdoor kitchen",
  "Build a pretend campsite",
  "Play Legos on the picnic table",
  "Color and draw outside",
  "Make a picnic",
  "Watercolor paint what you see in nature",
  "Play what's in the box",
  "Nature scavenger hunt",
  "Sidewalk chalk art",
  "Ride bikes",
  "Set up a sprinkler",
  "Nature craft time",
  "Outdoor movie night",
  "Make a safe yard obstacle course",
  "Blow bubbles",
  "Beach ball volleyball",
  "Nature exploration walk-even in the yard!",
  "Plant seeds",
  "Make a pot of flowers", 
  "Paint flower pots",
  "DIY water park",
  "Build a fort",
  "Outdoor art studio",
  "Make a sandcastle",
  "Tie dye some fun stuff",
  "Jump rope",
  "DIY mini golf",
  "Fly kites",
  "Hula hooping",
  "Set up a garden",
  "Read outside",
  "Make a mini beach",
  "Water gun tag",
  "Stargazing",
  "Sidewalk chalk jump patterns",
  "Bocce Ball", 
  "Croquet", 
  "Whiffle ball",
  "Play music outdoors",
  "Make a nature journal",
  "DIY nature suncatchers",
  "Make juice pops",
  "Make giant bubbles",
  "Boat races in a kiddie pool",
  "Play Frisbee"
];
export default function OutdoorsWarm() {
  const [list, setList] = useState(warmWeatherToDosOutdoors);
  const shuffleActivityList = () => {
    for (let i = list.length; --i; ) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    setList([...list]);
  };
  return (
    <>
      <li>{list[0]}</li>
      <li>{list[1]}</li>
      <li>{list[2]}</li>
      <li>{list[3]}</li>
      <li>{list[4]}</li>
      <button id="custom-suggestion-button" onClick={shuffleActivityList}>
        Shuffle activities
      </button>
    </>
  );
}
