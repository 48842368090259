import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/styles.scss";
import Weather from "./modules/Weather";
import Header from "./modules/Header";
// import { useState } from "react";


// let count = document.getElementById("count");
// function update() {
//   fetch("https://api.countapi.xyz/update/kiddiecast/deckerdevco?amount=1")
//     .then((res) => res.json())
//     .then((res) => {
//       count.innerText = res.value;
//     });
// }
// update();

function App() {
  // const [background, setBackground] = useState("");
  return (
    <div>
      <div className="App" style={{backgroundColor: '#f8f8ff'}}>
        <div className="container p-4 rounded">
          <Header />
          <div>
            {/*TODO: consider sending the default city from nearest GEO-Located location */}
            <Weather defaultCity="Columbus" />
          </div>
          <footer style={{textAlign: 'center'}}>
            Coded by Amy Stanley and friends of Gregory Alan Dev Co{" "}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
