import React from "react";
import "../styles/styles.scss";

export default function Header() {
  return (
    <div>
      <h1  style={{textAlign: 'center'}}>
        {" "}
        <img
          src={require("../media/kiddiecast-v1-2.png")}
          alt="Kiddie Cast logo"
          className="headerIcon"
          width={'60px'}
          height={'60px'}
        />
        Kiddie Cast
      </h1>
      <h4  style={{textAlign: 'center'}}>Keep your family playing together, no matter the weather!</h4>
    </div>
  );
}
