import React, { useState } from "react";

const chillyWeatherToDosOutdoors = [
  "Plan a cozy fire with family and friends",
  "Go on a rock hunt outside",
  "Create a things you found in nature collage",
  "Play a find the creature game with binoculars",
  "Collect twigs and wrap with colorful yarn for friends",
  "Make wind spinners with beads to hang in your trees",
  "Bundle up and go for a bike ride",
  "Take a winter sky star finding family walk after dark",
  "Make a bird feeder",
  "Use a hole punch to make nature confetti",
  "Plant some bulbs for spring",
  "Try to weave your own nest with items you found in nature",
  "Go for a family drive",
  "Collect pine cones",
  "Take pictures of things in nature",
  "Find some sticks and make a fence for toy animals",
  "Make nature confetti by hole punching leaves",
  "Collect flowers, berries and leaves for a nature collage",
  "Write kind words in chalk on your sidewalk",
  "Learn about what types of evergreens are around you",
  "Make a snowman",
  "Plant some onions",
  "Spot animal tracks on a walk",
  "Collect dried grass and make a nest",
  "Make maple syrup snow candy",
  "Study snowflakes that fall onto construction paper",
  "Check out the animals at your local zoo",
  "Make a percussion area with buckets, chimes, and cans-then dance and sing outside",
  "Go see how they tap trees for sap",
  "Make snow castles with your summer toys",
  "Go sledding",
  "Winter scavenger hunt (pincones, acorns, animal tracks, frozen puddles, icicles, feathers, lots of stuff to find!)",
  "Build a stick fort for your doll (don't forget to bring the doll back in!)",
  "Flashlight tag since the sun sets early",
  "Write letters or numbers with a stick in the snow",
  "Visit a botanical garden",
  "Winter photograph scavenger hunt",
  "Make stick wind chimes to give your family and friends",
  "Tic Tac Toe in the snow",
  "Plan a cozy fire with family and friends",
  "Go on a rock hunt outside",
  "Create a things you found in nature collage",
  "Play a find the creature game with binoculars",
  "Collect twigs and wrap with colorful yarn for friends",
  "Make wind spinners with beads to hang in your trees",
  "Bundle up and go for a bike ride",
  "Take a winter sky star finding family walk after dark",
  "Make a bird feeder",
  "Use a hole punch to make nature confetti",
  "Plant some bulbs for spring",
  "Try to weave your own nest with items you found in nature",
  "Nature scavenger hunt",
  "Draw pcitures of bugs you see",
  "Make leaf tracings",
  "Warm up with an outside dance party",
  "Go apple picking", 
  "Go to a pumpkin patch",
  "Find a local hayride",
  "Explore a corn maze",
  "Build mini scarecrows",
  "Leap over leaf piles",
  "Collect pinecones",
  "Make pinecone bird feeders",
  "Make a leaf garland",
  "Visit a farmer's market",
  "Check out the park",
  "Sketch what you see in your yard",
  "Family bike ride",
  "Fall themed obstacle course",
  "Story circle",
  "Make a nature book",
  "Build a mini stick city",
  "Make a leaf identification booklet",
  "Plant some fall bulbs",
  "Make tree rub art",
  "Trace leaves with sidewalk chalk",
  "Dissect a pinecone",
  "Visit a park",
  "Take binoculars on a nature walk",
  "Find winter constellations",
  "Make bark rubbings with crayons",
  "Make a leaf necklace",
  "Build a bug house with sticks, mud and leaves",
  "Spell your name with birdseed",
  "Go on a bird call scavenger hunt",
  "Go puddle jumping in your boots",
  "Paint the mud with a stick",
  "Set up a glow stick hunt after dark",
  "Shovel a snow maze",
  "Try to learn a bird call",
];
export default function OutdoorsChilly() {
  const [list, setList] = useState(chillyWeatherToDosOutdoors);
  const shuffleActivityList = () => {
    for (let i = list.length; --i; ) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    setList([...list]);
  };
  return (
    <>
      <li>{list[0]}</li>
      <li>{list[1]}</li>
      <li>{list[2]}</li>
      <li>{list[3]}</li>
      <li>{list[4]}</li>
      <button id="custom-suggestion-button" onClick={shuffleActivityList}>
        Shuffle activities
      </button>
    </>
  );
}
