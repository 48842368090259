import React from "react";
import { useState } from "react";
const warmWeatherToDosIndoors = [
  "Go to the library for story time and activities",
  "Make silly juice pops",
  "Go to the aquarium",
  "Do some ice cube excavations",
  "Make water sensory bins",
  "Beat the heat at the movies",
  "Make and float tiny surfboards in a pan with cardboard and tape",
  "Press flowers",
  "Play doh creations",
  "Go to the library for story time and activities",
  "Make silly juice pops",
  "Do some ice cube excavations",
  "Make water sensory bins",
  "Beat the heat at the movies",
  "Go to the library for story time and activities",
  "Make silly juice pops",
  "Do some ice cube excavations",
  "Make water sensory bins",
  "Beat the heat at the movies",
  "Create a collage",
  "Read a book per year of your age", 
  "Write a song",
  "Play animal charades",
  "Connect the dots",
  "Make your own lava lamps",
  "Paint with sponges",
  "Make slime",
  "Indoor Olympics with awards",
  "Make cardboard box cars",
  "Indoor beach in a plastic tote",
  "Go to the science museum",
  "Go to an indoor bounce house",
  "Coloring party!",
  "Make Play Doh creations",
  "Dance party!",
  "Cuddle up and read stories",
  "Bake something yummy!",
  "Video call family or friends",
  "Dress up",
  "Play kitchen",
  "Build a fort",
  "Go to the library for story time and activities",
  "Snuggle up at the movies",
  "Go to a music class",
  "Go to the aquarium",
  "Make pipecleaner crowns",
  "Board games",
  "Indoor obstacle course",
  "Movie marathon",
  "Bake a new recipe", 
  "Make homemade pizza",
  "Art party",
  "Make pipe cleaner animals",
  "Pipe cleaner flowers",
  "Indoor camping",
  "Puzzle time",
  "Set up a reading nook",
  "Make a volcano",
  "Indoor scavenger hunt",
  "Karaoke and dance party",
  "Indoor bowling",
  "Make peanut butter Play Doh",
  "Make Ooblek",
  "Virtual museum tours",
  "Create your own art gallery on a wall",
  "Write and act out a play",
  "Make paper airplanes",
  "Family photo shoot",
  "Tell each other stories",
  "Charades", 
  "Simon says", 
  "Paint what you wish the weather was",
  "Draw a picture of what you see out the window",
  "Make a comic strip",
  "Origami", 
  "Go indoor ice skating",
  "Homemade play kitchen",
  "Handprint animal crafts",
  "Make a puppet show",
  "Make a craft basket and create something fun",
  "Play balloon toss, inside or outside",
  "Paint some pet rocks",
  "Scavenger hunt",
  "Bowling with water bottles",
  "Make handprint pictures dinosaurs",
  "Set up a play date",
  "Build a fort",
  "Board games",
  "What's in the box challenge",
  "Cupcake decorating",
  "Dance party",
  "Learn to sing a new song together",
  "Read books",
  "Have a tea party",
  "Use painter's tape to make a race track on the floor",
  "Play kitchen",
  "Finger painting",
  "Make animals from modeling clay",
  "Have a friend over to play",
  "Make drums from pans",
  "Card games",
  "Hand print animals",
  "Make a scrapbook",
  "Find a bookstore with story time",
  "Put on a puppet show",
  "Take pictures of funny faces",
  "Shadow puppets on the wall",
  "Build with blocks",
  "Balloon toss",
];

export default function IndoorsWarm() {
  const [list, setList] = useState(warmWeatherToDosIndoors);
  const shuffleActivityList = () => {
    for (let i = list.length; --i; ) {
      let j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    setList([...list]);
  };
  return (
    <>
      <li>{list[0]}</li>
      <li>{list[1]}</li>
      <li>{list[2]}</li>
      <li>{list[3]}</li>
      <li>{list[4]}</li>
      <button id="custom-suggestion-button" onClick={shuffleActivityList}>
        Shuffle activities
      </button>
    </>
  );
}
